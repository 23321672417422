import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import $ from 'jquery'
import loadable from '@loadable/component'

import imgix from '../utils/imgix'
import Helmet from 'react-helmet'
import FramelessLayout from '../components/layouts/FramelessLayout'
import Header from '../components/shared/Header'
import Footer from '../components/shared/Footer'

const IsotopeGrid = loadable(() => import('../components/shared/IsotopeGrid'))

export default class IndexPage extends React.Component {
  componentDidMount = () => {
    const $win = $(window)
    const $body = $('body')

    $(window).on('resize', function(e) {
      if (window.RT) clearTimeout(window.RT)
      window.RT = setTimeout(function() {
        this.location.reload(false) /* false to get page from cache */
      }, 100)
    })

    // home page hero banner animation
    $('.js-animation').on('mouseenter', function() {
      if ($win.width() < 1200) {
        return
      }

      const $this = $(this)
      const direction = $this.data('direction')

      if (direction === 'left') {
        $body
          .removeClass(`has-banner-right`)
          .addClass(`has-banner-${direction}`)
      }

      if (direction === 'right') {
        $body.removeClass(`has-banner-left`).addClass(`has-banner-${direction}`)
      }
    })

    $('.js-animation-cancel').on('mouseenter', function() {
      if ($win.width() < 1200) {
        return
      }

      $body.removeClass(`has-banner-left`).removeClass(`has-banner-right`)
    })

    $('.section-banner .section__bg').on('mouseleave', function() {
      $body.removeClass('has-banner-left has-banner-right')
    })
  }

  render() {
    const { data } = this.props
    const seo = {
      title: data.wordpressPage.acf.seo_meta_title || '',
      description: data.wordpressPage.acf.seo_meta_description || '',
      robotsIndex: data.wordpressPage.acf.seo_index ? 'index' : 'noindex',
      robotsFollow: data.wordpressPage.acf.seo_follow ? 'follow' : 'nofollow',
    }

    const { edges: videos } = data.allWordpressWpVideo
    const { acf: page } = data.wordpressPage

    let items = []

    videos.map(video => {
      let videoPoster = '/assets/images/placeholder.png'

      if (video.node.youtube_video.thumbnails.maxres !== null) {
        videoPoster = video.node.youtube_video.thumbnails.maxres.url
      } else if (video.node.youtube_video.thumbnails.standard !== null) {
        videoPoster = video.node.youtube_video.thumbnails.standard.url
      } else if (video.node.youtube_video.thumbnails.high !== null) {
        videoPoster = video.node.youtube_video.thumbnails.high.url
      } else if (video.node.youtube_video.thumbnails.medium !== null) {
        videoPoster = video.node.youtube_video.thumbnails.medium.url
      } else if (video.node.youtube_video.thumbnails.default !== null) {
        videoPoster = video.node.youtube_video.thumbnails.default.url
      }

      let author = ''
      let authorProfession = ''
      let order = 0
      if (video.node.related_artist) {
        author = `${video.node.related_artist.acf.first_name} ${video.node.related_artist.acf.second_name}`
        authorProfession = video.node.related_artist.artist_category_name
      }

      if (video.node.acf) {
        order = video.node.acf.order
      }

      items.push({
        author: author,
        authorProfession: authorProfession,
        title: video.node.title.split('//')[0],
        videoUrl: video.node.snippet_url || '',
        videoPoster: videoPoster,
        videoPopup: `https://www.youtube.com/watch?v=${video.node.youtube_video.video_id}`,
        videoId: video.node.youtube_video.video_id,
        articleType: video.node.acf.block_size,
        category: 'all',
        order: parseInt(order),
      })
    })
    items = items.sort((itemA, itemB) => (itemA.order > itemB.order ? -1 : 1))

    return (
      <FramelessLayout>
        <Helmet
          title={seo.title}
          bodyAttributes={{
            class: 'has-header-white has-banner has-grouped-logo-mobile',
          }}
        >
          <html lang='en' />
          <link rel='canonical' href='https://boldbreed.de/' />
          {seo.description !== '' && (
            <meta name='description' content={seo.description} />
          )}
          <meta
            name='robots'
            content={seo.robotsIndex + ', ' + seo.robotsFollow}
          />
        </Helmet>

        <div className='wrapper'>
          <div className='wrapper__lines'></div>

          <div className='wrapper__inner'>
            <Header path={this.props.path} />

            <section className='section-banner'>
              <div className='section__image section__image--left'>
                <div className='cancel-animation-left js-animation-cancel'></div>
                <div
                  className='trigger-animation-left js-animation'
                  data-direction='left'
                ></div>
                <h3 className='section__title section__title--left'>
                  Commercial
                </h3>
                

                <div className='section__bg section__bg--left'>
                  <span
                    style={{
                      backgroundImage: `url(${imgix.buildURL(
                        page.banner_image_1.source_url,
                        {
                          w: 1200,
                          h: 900,
                        }
                      )})`,
                    }}
                  ></span>
                </div>
                <Link to='/commercial-artists/' className='btn btn--small btn-left'>
                  Enter
                </Link>
              </div>

              <div className='section__image section__image--right'>
                <div className='cancel-animation-right js-animation-cancel'></div>
                <div
                  className='trigger-animation-right js-animation'
                  data-direction='right'
                ></div>
                <h3 className='section__title section__title--right'>
                  Fiction
                </h3>

                <div className='section__bg section__bg--right'>
                  <span
                    style={{
                      backgroundImage: `url(${imgix.buildURL(
                        page.banner_image_2.source_url,
                        {
                          w: 1200,
                          h: 900,
                        }
                      )})`,
                    }}
                  ></span>
                </div>
                <Link to='/fiction-artists/' className='btn btn--small btn-right'>
                  Enter
                </Link>
              </div>

              <div className='section__logo'>
                <div>
                  <span
                    style={{
                      backgroundImage: 'url(/assets/images/logo-boldgreed.svg)',
                    }}
                  ></span>
                  <em>agency for creatives</em>
                </div>
              </div>
            </section>

            <section className='section-callout'>
              <div className='shell shell--fluid'>
                <header className='section__head'>
                  <h1>{page.block__1_heading}</h1>
                </header>

                <footer className='section__foot'>
                  <Link to='/contact/' className='btn'>
                    {page.block__1_cta}
                  </Link>
                  <div className='nl-container'>
                    <button className='nl-btn rm-open-popup nl-btn-offset'>
                      Subscribe to newsletter
                    </button>
                  </div>
                </footer>
              </div>
            </section>

            {page.featured_block_show === 'yes' && (
              <IsotopeGrid type='projects' items={items}>
                <header className='section__head'>
                  <div className='section__head-left'>
                    <p>{page.featured_block_text}</p>
                  </div>

                  <div className='section__head-right'>
                    <h2>featured</h2>
                  </div>
                </header>

                <div className='section__content'>
                  <div className='tabs'>
                    <div className='tabs__body'>
                      <div id='projects' className='tab is-active'>
                        <div className='grid grid--controls-overlap'>
                          <div className='grid__inner'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </IsotopeGrid>
            )}

            <section className='section-about'>
              <header className='section__head'>
                <div className='section__title'>
                  <p>
                    We
                    <br /> Are
                  </p>
                </div>

                <div className='section__image'>
                  <img
                    src={imgix.buildURL(page.block__2_image.source_url, {
                      w: 600,
                      h: 450,
                    })}
                    alt=''
                  />
                </div>
              </header>

              <div className='section__content'>
                <div className='section__content-title'>
                  <h3>{page.block__2_heading}</h3>
                </div>

                <div className='section__content-entry'>
                  <p>{page.block__2_text}</p>
                </div>

                <div className='section__content-actions'>
                  <Link to='/about/' className='btn'>
                    {page.block__2_cta}
                  </Link>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </FramelessLayout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressWpArtist: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query IndexQuery {
    wordpressPage(title: { eq: "Home" }) {
      acf {
        seo_meta_title
        seo_meta_description
        seo_index
        seo_follow
        banner_image_1 {
          source_url
        }
        banner_image_2 {
          source_url
        }
        block__1_cta
        block__1_heading
        block__2_cta
        block__2_heading
        block__2_image {
          source_url
        }
        block__2_text
        featured_block_show
        featured_block_text
      }
    }
    allWordpressWpVideo(
      filter: { acf: { home_page_featured: { eq: true } } }
      sort: { order: DESC, fields: acf___order }
    ) {
      edges {
        node {
          title
          snippet_url
          acf {
            block_size
            home_page_featured
            order
          }
          youtube_video {
            thumbnails {
              maxres {
                url
              }
              standard {
                url
              }
              high {
                url
              }
              medium {
                url
              }
              default {
                url
              }
            }
            video_id
          }
          related_artist {
            acf {
              first_name
              second_name
            }
            artist_category_name
          }
        }
      }
    }
  }
`
